import apiClient from '@/services/axios'

export const getVisitors = async (id, filterDate) => {
  const res = await apiClient.get('/receptionist/list', { params: { userId: id, filterDate } })
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const getDisableDate = async id => {
  const res = await apiClient.get('/receptionist/disable-date', { params: { userId: id } })
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const getLocations = async id => {
  const res = await apiClient.get('/receptionist/locations', { params: { userId: id } })
  if (res) {
    return res.data
  } else {
    return []
  }
}

export const addVisitor = async payload => {
  const res = await apiClient.post('/receptionist', payload)
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const outVisitor = async id => {
  const res = await apiClient.post('/receptionist/out', { id })
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const VisitorIn = async id => {
  const res = await apiClient.post('/receptionist/in', { id })
  if (res) {
    return res.data
  } else {
    return []
  }
}

export const cancelVisitors = async id => {
  const res = await apiClient.get('/receptionist/cancel', { params: { userId: id } })
  if (res) {
    return res.data
  } else {
    return []
  }
}
