<template>
  <div>
    <div class="d-flex align-items-center justify-content-between mb-3">
      <div class="d-flex align-items-center">
        <h2 class="mr-3">Buat Visitor Baru</h2>
      </div>
    </div>
    <a-card>
      <a-row class="mt-4" :gutter="[32, 32]">
        <a-col :span="24" class="d-flex justify-content-between align-items-center">
          <a-button @click="$router.back()">
            <LeftOutlined />Back
          </a-button>
          <!-- <a-button @click="changeEdittable">
              <span v-if="!editable"> <FormOutlined class="mr-2" />Edit </span>
              <span v-else> <EyeOutlined class="mr-2" />View </span>
            </a-button> -->
        </a-col>
        <a-col :span="24">
          <div>
            <h1 class="text-title">Visitor</h1>
          </div>
          <div>
            <a-row :gutter="[24, 12]">
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Tanggal</p>
                  <a-date-picker v-model:value="payload.date" class="w-100" :disabled-date="disabledDate" />
                </div>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Nama</p>
                  <a-input placeholder="Masukkan Nama Vendor" v-model:value="payload.name"></a-input>
                </div>
              </a-col>

              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Email</p>
                  <a-input placeholder="Masukkan Email Vendor" v-model:value="payload.email"></a-input>
                </div>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Nomor Handphone</p>
                  <a-input placeholder="Masukkan Nomor Handphone" v-model:value="payload.mobileNumber"></a-input>
                </div>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">PIC</p>
                  <a-input placeholder="Masukkan Nama PIC" v-model:value="payload.PIC"></a-input>
                </div>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Tipe Pengunjung</p>
                  <a-select placeholder="Pilih Tipe Pengunjung" class="w-100" v-model:value="payload.visitorType">
                    <a-select-option value="VIP">VIP</a-select-option>
                    <a-select-option value="Standard">Standard</a-select-option>
                    <a-select-option value="Vendor">Vendor</a-select-option>
                    <a-select-option value="Other">Other</a-select-option>
                    >
                  </a-select>
                </div>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }" v-if="payload.visitorType === 'Vendor'">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label" mode="multiple">Vendors</p>
                  <a-select placeholder="Pilih Tipe Tujuan Pengunjung" class="w-100" v-model:value="payload.vendorId">
                    <a-select-option :value="item.id" v-for="(item, index) in vendorsOptions" :key="index">{{
        item.name
      }}</a-select-option>
                    >
                  </a-select>
                </div>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Tujuan Pengunjung</p>
                  <a-select placeholder="Pilih Tipe Tujuan Pengunjung" class="w-100" v-model:value="payload.visitType">
                    <a-select-option value="Maintenance">Maintenance</a-select-option>
                    <a-select-option value="Install">Install</a-select-option>
                    <a-select-option value="Check">Check</a-select-option>
                    <a-select-option value="Troubleshooting">Troubleshooting</a-select-option>
                    <a-select-option value="Other">Other</a-select-option>
                    >
                  </a-select>
                </div>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Tujuan Lain Pengunjung</p>
                  <a-input placeholder="Masukkan Nama Vendor" v-model:value="payload.otherVisitType"></a-input>
                </div>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label" mode="multiple">Locations</p>
                  <a-select placeholder="Pilih Tipe Tujuan Pengunjung" class="w-100" v-model:value="payload.locationId">
                    <a-select-option :value="item.id" v-for="(item, index) in locationsOptions" :key="index">{{
        item.name
      }}</a-select-option>
                    >
                  </a-select>
                </div>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label" mode="multiple">Tambahan Pengunjung</p>
                  <a-input type="number" :min="1" :max="100000" v-model:value="payload.additionalPersons" />
                </div>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Catatan</p>
                  <a-textarea v-model:value="payload.notes" />
                </div>
              </a-col>
            </a-row>
          </div>
        </a-col>

        <a-col :span="24">
          <a-row :gutter="[24, 12]">
            <a-col :span="18"></a-col>
            <a-col :span="6" class="d-flex justify-content-end align-items-center" style="gap:15px">
              <a-button @click="clearPayload()" type="primary">Clear</a-button>
              <a-button @click="submitPayload()">Submit</a-button>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { onMounted, ref, createVNode, computed, reactive, onBeforeMount, watch } from 'vue'
import { defineAsyncComponent } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import {
  LeftOutlined,
} from '@ant-design/icons-vue'
import { message, Modal, notification, Form } from 'ant-design-vue'
import { getCustomers, postUser } from '../../services/axios/modc/users'
import { addVisitor, getLocations, getDisableDate } from '../../services/axios/modc/receptionist'
import { getVendors } from '../../services/axios/modc/vendor'
import moment from 'moment'


export default {
  components: { LeftOutlined },
  setup() {
    const payload = ref({
      id: 1,
      date: "",
      receptionistId: 1,
      name: "John Doe",
      email: "johndoe@example.com",
      PIC: 'Ahmad',
      mobileNumber: "+1234567890",
      visitorType: "Vendor",
      vendorId: 'Pilih Vendor',
      visitType: "Maintenance",
      otherVisitType: "First Installation",
      additionalPersons: 2,
      notes: "Needs assistance with setup",
      createdAt: "2023-01-01T08:30:00Z",
      updatedAt: "2023-01-01T08:30:00Z",
      locationId: 'Pilih Lokasi',
    })
    let customersOptions = ref([])
    let locationsOptions = ref([])
    let vendorsOptions = ref([])
    let disabledDateData = ref([])
    const clearPayload = () => {
      payload.value = {
        userSign: '',
        username: '',
        email: '',
        mobileNumber: '',
        role: '',
        customers: [],
        locations: [],
      }
    }
    const router = useRouter()
    const submitPayload = async () => {
      const storedUserData = localStorage.getItem('userData');
      const userData = JSON.parse(storedUserData);
      payload.value.userId = userData.user.id
      const data = await addVisitor(payload.value)
      router.push('/receptionist')
      // $router.back()
    }
    const disabledDate = current => {
      // console.log(current, '==>>inicurren')
      console.log(disabledDateData.value)

      // Can not select days before today and today
      return current && current < moment().subtract(1, 'days').endOf('day') || disabledDateData.value.includes(current.format('YYYY-MM-DD'));;
    };

    const fetchCustomers = async (search = '') => {
      const { customer } = await getCustomers()
      // console.log(customer)
      const customersData = customer.map(e => ({ value: e.name }),
      )

      customersOptions.value = customersData
    }

    const fetchLocations = async (search = '') => {
      const storedUserData = localStorage.getItem('userData');
      const userData = JSON.parse(storedUserData);
      const { locations } = await getLocations(userData.user.id)
      locationsOptions.value = locations
    }
    const fetchVendors = async (search = '') => {
      const { vendor } = await getVendors()
      vendorsOptions.value = vendor
    }
    const fetchDisableDate = async (search = '') => {
      const storedUserData = localStorage.getItem('userData');
      const userData = JSON.parse(storedUserData);
      const { disabledDates } = await getDisableDate(userData.user.id)
      disabledDateData.value = disabledDates
    }

    onMounted(() => {
      fetchCustomers()
      fetchLocations()
      fetchVendors()
      fetchDisableDate()
    })
    // watch(payload.value, (newValue, oldValue) => {
    //   fetchLocations();
    // });


    return {
      payload,
      clearPayload,
      submitPayload,
      customersOptions,
      locationsOptions,
      vendorsOptions,
      fetchCustomers,
      fetchLocations,
      fetchVendors,
      disabledDate,
      fetchDisableDate,
      disabledDateData,
    }
  },
}
</script>
<style lang="scss">
@import '../styleViews.scss';

.text-title {
  font-size: 1.5rem;
  color: $black;
  font-weight: 500;
  margin-bottom: 10px;
}

.text-subtitle {
  font-size: 1.3rem;
  color: $black;
  font-weight: 500;
  margin-bottom: 10px;
}

.text-label {
  font-size: 1rem;
  color: $text;
  font-weight: 400;
  margin-bottom: 10px;
}

// . {
//   background-color: white !important;
//   input {
//     background-color: white !important;
//   }
// }
// :global(.ant-select-.ant-select:not(.ant-select-customize-input)) {
//   :global(.ant-select-selector) {
//     background-color: white !important;
//   }
// }
// .ant-input .ant- {
//   background-color: #ffffff !important;
// }
.th-upload-wrapper {
  .ant-upload-picture-card-wrapper {
    display: flex;
    justify-content: center;

    .ant-upload {
      width: 300px;
      height: 100px;
    }
  }
}
</style>
